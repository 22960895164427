<template>
  <b-row>
    <b-col
      v-for="tagSet in tagStatistics"
      :key="tagSet.id"
      cols="6"
    >
      <b-card
        no-body
        class="p-75"
      >
        <b-card-body>
          <h5 class="mb-2">
            {{ tagSet.tag_set_name }}
          </h5>
          <b-row>
            <b-col
              v-for="(tag, index) in tagSet.tags"
              :key="index"
              cols="3"
            >
              <h4>
                {{ tag.applications_count }}
              </h4>
              <p class="align-middle">
                {{ tag.name }}
              </p>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col
      cols="12"
    >
      <b-card
        no-body
      >
        <!-- Table Top -->
        <b-row class=" m-2">

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="perPage"
              :options="perPageOptions"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="w-100"
          >
            <v-select
              v-model="filterDataGroups"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="allGroupsList"
              multiple
              label="name"
              :reduce="option => option.id"
              placeholder="Select Groups"
            />
          </b-col>

          <b-col
            cols="12"
            md="6"
            class="w-100"
          >
            <div class="d-flex align-items-center justify-content-end">
              <treeselect
                v-model="selectedTags"
                :multiple="true"
                :options="tagSetOptionsList"
                search-nested
                :default-expand-level="1"
                :disable-branch-nodes="true"
                placeholder="Select Tags"
                class="w-75"
                @close="tagsSelected"
              />
              <label class="mx-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25 mr-1"
              />
            </div>
          </b-col>
        </b-row>

        <b-table
          ref="tagViewTable"
          :items="tableItems"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          sticky-header="75vh"
          details-td-class="p-0"
          :busy="isLoading"
          empty-text="No matching records found"
          class="position-relative table-sticky-head"
        >
          <!-- Loader -->
          <template #table-busy>
            <TableSpinner />
          </template>

          <template #cell(selected)="data">
            <b-form-checkbox
              v-model="markedElements"
              :value="data.item.id"
              inline
            />
          </template>

          <!-- Column: name -->
          <template #cell(name)="data">
            <b-link
              :disabled="!canViewApplicationData(data.item)"
              :to="getUserViewRoutByType(data.item)"
            >1{{ data.item.user.full_name }}</b-link>
          </template>

          <template #row-details>
            <div />
          </template>

          <!-- Column: tags -->
          <template #cell(tags)="data">
            <div class="d-flex justify-content-between">
              <badge-tag-list
                v-if="!$can('assign', permissionSubjects.Tags)"
                :tag-names="data.item.tags"
              />
              <badge-tag-list
                v-else-if="!data.detailsShowing"
                :tag-names="data.value"
                class="cursor-pointer"
                @click.native="data.toggleDetails"
              />
              <tags-edit-selector
                v-else
                :application-id="data.item.id"
                :tags="data.value"
                :tag-set-list="tagSetOptionsList"
                @tagsUpdated="updateTableItemTags(data.item.id, $event)"
                @closeEditing="data.toggleDetails"
              />
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-if="totalItems && perPage !== 'all'"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>

          <b-row>
            <b-col
              cols="12"
              class="d-flex align-items-center mt-2"
            >
              <b-dropdown
                id="dropdown-buttons"
                text="Bulk Actions"
                variant="outline-danger"
              >
                <b-dropdown-item
                  v-if="$can('add', permissionSubjects.GroupMember)"
                  v-b-modal.bulk-add-to-group-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Add To Group
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="canSendBulkCommunication"
                  v-b-modal.bulk-communication-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Communication
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('create', permissionSubjects.Charge)"
                  v-b-modal.bulk-adding-charge-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Charge
                </b-dropdown-item>
                <b-dropdown-item
                  v-b-modal.bulk-generate-letter-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Generate Letter
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('assign', permissionSubjects.Tags)"
                  v-b-modal.bulk-tag-assign-modal
                  :disabled="markedElements.length < 1"
                >
                  Bulk Tag Assign
                </b-dropdown-item>
              </b-dropdown>
            </b-col>

          </b-row>
        </div>
      </b-card>

    </b-col>

    <bulk-communicate-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :total-elements-count="markedElements.length"
      :filter-name="'application_id'"
      @successfullySend="bulkCommunicateSuccess"
    />

    <bulk-add-to-group-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_id'"
      @successfullySend="bulkAddToGroupSuccess"
    />

    <bulk-adding-charge-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_id'"
      :program-id="programId"
      @success="bulkCreateChargeSuccess"
    />

    <bulk-generate-letter-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_id'"
      :program-name="programId"
      @success="bulkGenerateLetterSuccess"
    />

    <bulk-tag-assign-modal
      v-if="markedElements.length"
      :marked-elements="markedElements"
      :filter-name="'application_id'"
      :program-id="programId"
      @success="bulkTagAssignSuccess"
    />

    <application-tags-edit-modal
      v-if="selectedApplication"
      :tag-names="selectedApplication.tags"
      :application-id="selectedApplication.id"
      @tagsUpdated="updateTable"
    />

  </b-row>

</template>

<script>
import { mapGetters } from 'vuex'
import { onUnmounted } from '@vue/composition-api'

import {
  BCard, BCardBody, BRow, BCol, BTable, BFormInput,
  BDropdown, BDropdownItem, BPagination, BLink, BFormCheckbox,
} from 'bootstrap-vue'
import Treeselect from '@riophae/vue-treeselect'
import vSelect from 'vue-select'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'

import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import BadgeTagList from '@/views/components/badge-tag-list/BadgeTagList.vue'
import BulkCommunicateModal from '@/views/components/bulk-communicate-modal/BulkCommunicateModal.vue'
import BulkAddToGroupModal from '@/views/components/bulk-add-to-group/BulkAddToGroupModal.vue'
import BulkAddingChargeModal from '@/views/components/adding-charge-modal/BulkAddingChargeModal.vue'
import BulkGenerateLetterModal from '@/views/components/bulk-generate-letter/BulkGenerateLetterModal.vue'
import BulkTagAssignModal from '@/views/components/bulk-tag-assign/BulkTagAssignModal.vue'
import ApplicationTagsEditModal from '@/views/components/application-tags-edit-modal/ApplicationTagsEditModal.vue'
import TagsEditSelector from '@/views/components/tags-edit-selector/TagsEditSelector.vue'
import { permissionSubjects } from '@/libs/acl/constants'

import store from '@/store'
import storeModule from '@/views/admin/tag-view/tagViewStoreModule'

let debounceTimeout = setTimeout(() => '', 1000)
export default {
  name: 'TagView',
  components: {

    BCard,
    BCardBody,
    BRow,
    BCol,
    BTable,
    BFormInput,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,
    BFormCheckbox,

    TableSpinner,
    BadgeTagList,

    BulkCommunicateModal,
    BulkAddToGroupModal,
    BulkAddingChargeModal,
    BulkGenerateLetterModal,
    BulkTagAssignModal,
    ApplicationTagsEditModal,
    TagsEditSelector,

    Treeselect,
    vSelect,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      permissionSubjects,
    }
  },
  computed: {
    ...mapGetters({
      tableColumns: 'app-tag-view/getTableColumns',
      tableItems: 'app-tag-view/getTableItems',
      perPage: 'app-tag-view/getPerPage',
      totalItems: 'app-tag-view/getTotalItems',
      perPageOptions: 'app-tag-view/getPerPageOptions',
      sortBy: 'app-tag-view/getSortBy',
      isSortDirDesc: 'app-tag-view/getIsSortDirDesc',
      dataMeta: 'app-tag-view/getDataMeta',
      tagSetOptionsList: 'tags/getTagSetOptionsList',
      allGroupsList: 'app-tag-view/getAllGroupsList',
      tagStatistics: 'app-tag-view/getTagStatistics',
      applicationsList: 'app-tag-view/getApplicationsList',
      isLoading: 'app-tag-view/getIsLoading',
      programId: 'verticalMenu/getDefaultProgram',
      userRole: 'verticalMenu/getUserRole',
      selectedApplication: 'app-tag-view/getSelectedApplication',
    }),
    currentPage: {
      get() {
        return this.$store.getters['app-tag-view/getCurrentPage']
      },
      set(val) {
        this.$store.commit('app-tag-view/SET_CURRENT_PAGE', val)
      },
    },
    searchQuery: {
      get() {
        return this.$store.getters['app-tag-view/getSearchQuery']
      },
      set(val) {
        this.$store.commit('app-tag-view/SET_SEARCH_QUERY', val)
      },
    },
    selectedTags: {
      get() {
        return this.$store.getters['app-tag-view/getSelectedTags']
      },
      set(val) {
        this.$store.commit('app-tag-view/SET_SELECTED_TAGS', val)
      },
    },
    filterDataGroups: {
      get() {
        return this.$store.getters['app-tag-view/getFilterDataGroups']
      },
      set(val) {
        this.$store.commit('app-tag-view/SET_FILTER_DATA_GROUPS', val)
      },
    },
    markedElements: {
      get() {
        return this.$store.getters['app-tag-view/getMarkedElements']
      },
      set(val) {
        this.$store.commit('app-tag-view/SET_MARKED_ELEMENTS', val)
      },
    },
    canSendBulkEmailCommunication() {
      return this.$can('send', permissionSubjects.BulkEmailCommunication)
    },
    canSendBulkSmsCommunication() {
      return this.$can('send', permissionSubjects.BulkSMSCommunication)
    },
    canSendBulkCommunication() {
      return this.canSendBulkEmailCommunication || this.canSendBulkSmsCommunication
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-tag-view'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  watch: {
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
    selectedTags(val) {
      if (val.length === 0) {
        this.fetchTagStatistics()
        pushStateFiltersParams(this, this.currentRouteName, 'selectedTags', val)
      }
    },
    filterDataGroups(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'filterDataGroups', val)
      }
    },
    async programId() {
      this.$store.commit('app-tag-view/RESET_STATE')
      await this.fetchTagSetsListOptions()
      this.updateTable()
    },
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
  },
  created() {
    this.fetchTagSetsListOptions()
    this.fetchAllGroupsList()
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }

    this.updateTable()
  },
  beforeDestroy() {
    this.$store.commit('app-tag-view/RESET_STATE')
  },
  methods: {
    updatePerPage(val) {
      this.$store.commit('app-tag-view/SET_PER_PAGE', val)
    },
    async fetchTagViewList() {
      return this.$store.dispatch('app-tag-view/fetchTagViewList')
    },
    updateTable() {
      this.$store.dispatch('app-tag-view/fetchTagViewList')
    },
    updateTableItemTags(id, tags) {
      const item = this.tableItems.find(item => item.id === id)
      if (item) item.tags = tags
      this.$forceUpdate()
    },
    async fetchTagSetsListOptions() {
      await this.$store.dispatch('tags/prepareTagSetsListOptions', { programId: this.programId })
    },
    async fetchTagStatistics() {
      if (this.selectedTags.length === 0) {
        this.$store.commit('app-tag-view/SET_TAG_STATISTICS', [])
        return
      }
      const queryParams = {
        tag_id: this.selectedTags,
        with_empty_tags_on_statistics: true,
      }

      await this.$store.dispatch('app-tag-view/fetchTagStatistics', { queryParams })
    },
    async fetchAllGroupsList() {
      const queryParams = {
        exceptProgramType: 'Program',
        programId: this.programId,
      }

      await this.$store.dispatch('app-tag-view/fetchAllGroupsList', queryParams)
    },
    async bulkCommunicateSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-communication-modal')
      this.updateTable()
    },
    async bulkAddToGroupSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-add-to-group-modal')
      this.updateTable()
    },
    async bulkCreateChargeSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-adding-charge-modal')
      this.updateTable()
    },
    async bulkGenerateLetterSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-generate-letter-modal')
    },
    async bulkTagAssignSuccess() {
      this.markedElements = []
      this.$bvModal.hide('bulk-tag-assign-modal')
      this.updateTable()
    },
    setFilterParams() {
      this.$store.commit('app-tag-view/SET_FILTER_PARAMS')
      this.fetchTagStatistics()
    },
    getApplicationUserType(user) {
      return user.role.display_name === 'Staff' ? user.role.display_name : this.camperStudent(1)
    },
    tagsSelected() {
      this.fetchTagStatistics()
      pushStateFiltersParams(this, this.currentRouteName, 'selectedTags', this.selectedTags)
    },
    editApplicationTags(val) {
      this.$store.commit('app-tag-view/SET_SELECTED_APPLICATION', val)
      this.$nextTick(() => {
        this.$bvModal.show('application-tags-edit')
      })
    },
    getUserViewRoutByType(item) {
      if (item.user.is_student) {
        return { name: 'admin-application-edit', params: { id: item.id, type: this.camperStudent(1) } }
      }
      return { name: 'admin-application-edit', params: { id: item.id, type: 'Staff' } }
    },
    canViewApplicationData(item) {
      if (item.user.role.display_name === 'Staff') {
        return this.$can('read', this.permissionSubjects.StaffApplication)
      }
      return this.$can('read', this.permissionSubjects.ChildApplication)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
